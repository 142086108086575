/*
 * ACTIONS
 */
const UPDATE_RISKS = 'UPDATE_RISKS';

/*
 * ACTION CREATORS
 */
export const update = (risk) => ({
  type: UPDATE_RISKS,
  risk,
});

/*
 * REDUCERS
 */
const defaultState = {
  stroke: 0,
  bleeding: 0,
};

// eslint-disable-next-line default-param-last
const main = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_RISKS:
      return { ...state, ...action.risk };
    default:
      return state;
  }
};

export default main;
