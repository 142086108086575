import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Container, Grow, Actions } from './styled/Wrappers';
import { Text, ListItem } from './styled/Typography';
import theme from './styled/theme';
import { Link } from './styled/Buttons';
import { setUnsure } from '../reducers/ui';
import Nav from './Nav';

const CustomLink = styled(Link)`
  margin-top: 10px;
`;

const Wrapper = styled(Grow)`
  padding: ${theme.gutter};
`;

function Gate({ dispatch }) {
  return (
    <>
      <Nav />
      <Container>
        <Wrapper>
          <Text>
            Is the <strong>RAPID AFib Decision Aid</strong> right for you?
          </Text>
          <Text>
            The <strong>RAPID AFib Decision Aid</strong> is designed to help you, the individual
            with atrial fibrillation, choose which, if any, oral anticoagulants to reduce your
            chances of having a stroke.
          </Text>
          <Text>
            But, if you have had one or more of the following conditions, this decision aid is not
            right for you:
          </Text>
          <ul>
            <ListItem>Artificial heart valve</ListItem>
            <ListItem>Recent coronary procedures</ListItem>
            <ListItem>End-stage kidney disease</ListItem>
          </ul>
          <Actions style={{ flexDirection: 'column', alignItems: 'center' }}>
            <CustomLink to="/aidnotright">I Have One or More of These Conditions</CustomLink>
            <CustomLink to="/disclaimer">I Don’t Have Any of These Conditions</CustomLink>
            <CustomLink
              onClick={() => {
                dispatch(setUnsure());
              }}
              to="/disclaimer"
            >
              I’m Not Sure If I Have Any of These Conditions
            </CustomLink>
          </Actions>
        </Wrapper>
      </Container>
    </>
  );
}

export default connect()(Gate);

Gate.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
