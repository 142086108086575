import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import Outro from './Outro';
import { Text } from '../styled/Typography';
import { Actions, Centered } from '../styled/Wrappers';
import { TextLink } from '../styled/Buttons';
import * as Q from '../styled/Question';
import { BLEEDING_QUESTIONS } from '../../config/questions';

const FullHeightTransition = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.$grow ? '1' : '0 1 auto')};
  position: relative;
`;

function Question({ defaultScore }) {
  const [index, setIndex] = useState(0);
  const conditionalRef = useRef(null);
  const previousRef = useRef(null);
  const [answers, setAnswers] = useState([]);
  const [back, setAnimationBack] = useState(false);
  let { question, options } = BLEEDING_QUESTIONS[index] || { options: [] };
  const questionCount = BLEEDING_QUESTIONS.length;
  const isInitialMount = useRef(true);

  const handleBack = () => {
    setAnimationBack(true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (back) {
      if (previousRef.current === null && conditionalRef.current === null) {
        setIndex((idx) => idx - 1);
      } else if (conditionalRef.current) {
        // User hit back from the conditional screen
        conditionalRef.current = null;
        // the set change is needed to force a re-render
        setAnswers((oldAnswers) => {
          return { ...oldAnswers };
        });
        // ({ question, options } = BLEEDING_QUESTIONS[index] || { options: [] });
      } else {
        // User hit back from the screen after a conditional
        conditionalRef.current = previousRef.current;
        setIndex((idx) => idx - 1);
      }

      previousRef.current = null;
    }
  }, [back]);

  const score = Object.keys(answers).reduce((sum, key) => {
    let nestedQuestion = null;

    let o = BLEEDING_QUESTIONS.find((q) => {
      if (q.question === key) return true;

      [nestedQuestion] = Object.values(q.options);

      if (typeof nestedQuestion !== 'object') nestedQuestion = null;

      return nestedQuestion;
    });

    if (o) {
      if (nestedQuestion) o = nestedQuestion;

      return sum + (typeof o.options[answers[key]] === 'number' ? o.options[answers[key]] : 0);
    }

    return sum;
  }, defaultScore);

  if (conditionalRef.current) {
    ({ question, options } = conditionalRef.current);
  }

  // useEffect(() => {
  //   ({ question, options } = conditionalRef.current);
  // }, [conditionalRef.current]);

  const completed = index >= questionCount;

  return (
    <FullHeightTransition $grow={completed}>
      <CSSTransition
        key={`question_${index}_c${conditionalRef.current}`}
        classNames="page"
        timeout={{ enter: 1100, exit: 900 }}
        onExited={() => setAnimationBack(false)}
      >
        {(state) => {
          if (completed) {
            const tmpAnswers = Object.keys(answers).map((q) => ({
              question: q,
              answer: answers[q],
            }));

            return (
              <Outro
                score={score}
                answers={tmpAnswers}
                onBack={handleBack}
                state={state}
                previous={back}
              />
            );
          }

          return (
            <Q.Wrapper className="question" $previous={back}>
              <Q.Header>
                {index === 0 && conditionalRef.current === null && (
                  <Q.IntroText>
                    Please answer the following bleeding-related questions. Your answers to these
                    questions will help you determine your chance of bleeding.
                  </Q.IntroText>
                )}
                <Q.Ribbon>
                  <Q.RibbonText>
                    {`Bleeding Question ${index + 1}${conditionalRef.current ? 'b' : ''}`}
                    <Q.MutedText>{` of ${questionCount}`}</Q.MutedText>
                  </Q.RibbonText>
                  <Text dangerouslySetInnerHTML={{ __html: question }} />
                </Q.Ribbon>
              </Q.Header>
              <Centered>
                {Object.keys(options).map((o, i) => (
                  <Q.CustomButton
                    key={`q${index}o${i}`}
                    selected={answers[question] === Object.keys(options)[i]}
                    onClick={() => {
                      const selected = Object.keys(options)[i];
                      const newAnswers = { ...answers, [question]: selected };

                      setAnswers(newAnswers);
                      document.querySelector('#root > section').scrollTop = 0;

                      if (
                        conditionalRef.current === null &&
                        typeof options[selected] === 'object' &&
                        options[selected] !== null
                      ) {
                        conditionalRef.current = options[selected];
                      } else {
                        previousRef.current = conditionalRef.current;
                        conditionalRef.current = null;
                        setIndex(index + 1);
                      }
                    }}
                  >
                    {o}
                  </Q.CustomButton>
                ))}
              </Centered>
              <Actions>
                {(index > 0 || conditionalRef.current) && (
                  <TextLink onClick={handleBack}>Previous</TextLink>
                )}
              </Actions>
            </Q.Wrapper>
          );
        }}
      </CSSTransition>
    </FullHeightTransition>
  );
}

Question.propTypes = {
  defaultScore: PropTypes.number.isRequired,
};

function mapStateToProps({ ui: { age, stroke } }) {
  let defaultScore = 0;
  if (age === '65 to 74 years' || age === '75 years or older') {
    defaultScore += 1;
  }

  if (stroke) {
    defaultScore += 1;
  }

  return {
    defaultScore,
  };
}

export default connect(mapStateToProps)(Question);
