import React from 'react';
import styled from 'styled-components';

import { Actions, Container, Grow } from './styled/Wrappers';
import { Link } from './styled/Buttons';
import { Bold, ListItem, Text } from './styled/Typography';
import { Ribbon } from './styled/Ribbon';

function RAPIDAFib() {
  return <Bold> RAPID AFib Decision Aid </Bold>;
}

const BackgroundContainer = styled(Container)`
  position: relative;
  z-index: 1;
  &:after {
    display: block;
    mix-blend-mode: normal;
    opacity: 1;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    overflow-y: hidden;
  }
`;

function Home() {
  return (
    <BackgroundContainer className="home">
      <Banner>
        <div>
          <BannerText data-article="Using">Risk</BannerText>
          <BannerText>Assessment</BannerText>
          <BannerText data-article="and">Personal Preference</BannerText>
          <BannerText data-article="to">Improve</BannerText>
          <BannerText>Decisions</BannerText>
          <br />
          <BannerText data-article="for">Atrial</BannerText>
          <BannerText>Fibrillation</BannerText>
        </div>
      </Banner>
      <CenteredGrow>
        <Pad>
          <Text>
            If you have been diagnosed with atrial fibrillation, the
            <RAPIDAFib />
            will help you:
          </Text>
          <ul>
            <ListItem>learn more about the possibility of you having a stroke</ListItem>
            <ListItem>choose which, if any, oral anticoagulants may be best for you</ListItem>
          </ul>
        </Pad>
        <Divider />
        <CompletionText>
          Completing the <RAPIDAFib /> will take you about 10 minutes.
        </CompletionText>
        <Actions>
          <Link to="/gate">Start</Link>
        </Actions>
      </CenteredGrow>
    </BackgroundContainer>
  );
}

const Banner = styled(Ribbon)`
  display: flex;
  justify-content: center;
  padding: 16px;

  @media (min-width: 370px) {
    margin-top: 16px;
  }
`;

const BannerText = styled(Text)`
  color: inherit;
  font-size: 19px;
  letter-spacing: -0.81px;
  position: relative;
  margin: 0 0 5px 0;

  &::before {
    right: 100%;
    bottom: 0;
    position: absolute;
    padding-right: 7px;
    content: attr(data-article);
  }

  &::first-letter {
    font-weight: 700;
    font-size: 25px;
  }
`;

const Pad = styled.div`
  padding: 0 26px;
`;

const CenteredGrow = styled(Grow)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  background-color: rgba(222, 226, 229, 0.5);
  height: 1px;
  width: 100%;

  @media (min-width: 370px) {
    margin-bottom: 10px;
  }
`;

const CompletionText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 26px;
`;

export default Home;
