import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Container } from './styled/Wrappers';
import Question from './stroke/Question';
import Outro from './stroke/Outro';
import Medication from './stroke/Medication';
import NoMedication from './stroke/NoMedication';
import Risk from './stroke/Risk';
import Nav from './Nav';
import ProgressBar from './ProgressBar';

function Stroke() {
  const location = useLocation();

  return (
    <>
      <Nav />
      <ProgressBar step={location.pathname === '/stroke' ? 1 : 2} />
      <Container className="container">
        <Routes>
          <Route path="outro" element={<Outro />} />
          <Route path="risk" element={<Risk />} />
          <Route path="no-medication" element={<NoMedication />} />
          <Route path="medication" element={<Medication />} />
          <Route path="*" element={<Question />} />
        </Routes>
      </Container>
    </>
  );
}

export default Stroke;
