import styled from 'styled-components';
import theme from './theme';

const Screenshot = styled.img`
  border: 1px solid ${theme.colours.secondary};
  margin-top: 20px;
  max-width: 100%;
`;

export default Screenshot;
