import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from './styled/Typography';
import theme from './styled/theme';

const Info = styled(Text)`
  margin: 0;
  padding: 5px 11px;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
`;

const Wrapper = styled.div`
  box-shadow: 0 4px 9px -2px rgba(212, 216, 219, 0.89);
  width: 100%;
  max-width: ${theme.navWidth};
  height: auto;
  overflow: visible;
  padding: 0;
  margin: auto;
`;

const arrowLength = 8;
const arrowHeight = 32;
const Progress = styled.ul`
  display: flex;
  padding: 0;
  list-style-type: none;
  text-align: center;
  margin: 0;

  li {
    align-items: center;
    background: linear-gradient(270deg, ${theme.colours.active} 0%, #828ba0 121.93%);
    color: #fff;
    display: flex;
    flex: 1 1 auto;
    font-family: ${theme.fontFamily};
    font-size: 10px;
    font-weight: 500;
    height: ${arrowHeight - 2}px;
    justify-content: center;
    margin: 0;
    position: relative;

    @media (min-width: 370px) {
      font-size: 13px;
      height: ${arrowHeight}px;
    }
  }

  li + li:before {
    content: '';
    border-left: ${arrowLength}px solid #fff;
    border-top: ${arrowHeight / 2}px solid transparent;
    border-bottom: ${arrowHeight / 2}px solid transparent;
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: 360px) {
      border-left: ${arrowLength - 2}px solid #fff;
      border-top: ${(arrowHeight - 2) / 2}px solid transparent;
      border-bottom: ${(arrowHeight - 2) / 2}px solid transparent;
    }
  }

  li ~ li {
    padding-left: 6px;
  }

  li:after {
    content: '';
    border-left: ${arrowLength}px solid ${theme.colours.active};
    border-top: ${arrowHeight / 2}px solid transparent;
    border-bottom: ${arrowHeight / 2}px solid transparent;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 20;

    @media (max-width: 360px) {
      border-left: ${arrowLength - 2}px solid ${theme.colours.active};
      border-top: ${(arrowHeight - 2) / 2}px solid transparent;
      border-bottom: ${(arrowHeight - 2) / 2}px solid transparent;
    }
  }

  li:nth-child(${(props) => props.step || 0}n) {
    font-weight: 600;
  }

  li:nth-child(${(props) => props.step || 0}n) ~ li {
    background: ${theme.colours.grey};
    color: ${theme.colours.white};
    font-weight: 500;
    margin-right: 1px;

    &:after {
      border-left-color: ${theme.colours.grey};
    }
  }
`;

function ProgressBar({ step }) {
  return (
    <Wrapper>
      <Progress step={step}>
        <li>About</li>
        <li>Stroke</li>
        <li>Bleeding</li>
        <li>Options</li>
        <li>Summary</li>
      </Progress>
      <Info>
        Percentage Complete <strong>{(step / 5) * 100}%</strong>
      </Info>
    </Wrapper>
  );
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ProgressBar;
