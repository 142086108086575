import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import unhappy from '../../images/unhappy.svg';

import fadeIn from '../styled/Animations';
import { Hr, H4, Ul, ListItem } from '../styled/Typography';
import { Link } from '../styled/Buttons';
import { Matrix, Smiley, Summary, Legend } from '../styled/Matrix';
import { Actions, PaddedWrapper } from '../styled/Wrappers';
import { InfoRibbon, BlueBorderedText } from '../styled/InfoRibbon';
import { STROKE_CHANCE } from '../../config/stats';

const Action = styled(Link)`
  align-self: center;
  margin-top: auto;
`;

const Wrapper = styled.section`
  ${fadeIn}
`;

const total = 100;
function NoMedication({ stroke }) {
  const percentChance = STROKE_CHANCE.noMedication[stroke];
  const unaffected = total - total * (percentChance / 100);
  return (
    <Wrapper>
      <PaddedWrapper>
        <InfoRibbon>
          <BlueBorderedText>
            Without an oral anticoagulant,{' '}
            <strong>
              {percentChance} {percentChance > 1 ? 'people' : 'person'} in a group of {total} people
              like you
            </strong>{' '}
            will probably have a <strong>stroke</strong> in the next year. This means{' '}
            <strong>{total - STROKE_CHANCE.noMedication[stroke]}</strong> out of those 100 people
            will probably not have a stroke in the next year.
          </BlueBorderedText>
        </InfoRibbon>
      </PaddedWrapper>
      <Matrix>
        {[...Array(percentChance)].map((_, i) => (
          <Smiley key={`pc${i}`} imgSrc={unhappy} />
        ))}
        {[...Array(unaffected)].map((_, i) => (
          <Smiley key={`ua${i}`} />
        ))}
      </Matrix>
      <Hr />
      <PaddedWrapper>
        <Legend>
          <H4>Legend</H4>
          <Ul>
            <ListItem>
              <Smiley imgSrc={unhappy} /> They are likely to have a stroke
            </ListItem>
            <ListItem>
              <Smiley /> They are likely to not have a stroke
            </ListItem>
          </Ul>
        </Legend>
      </PaddedWrapper>
      <Hr />
      <Summary>
        In other words, without an oral anticoagulant, your chance of having a stroke is{' '}
        {percentChance}% in the next year.
      </Summary>
      <Actions>
        <Action to="/stroke/medication">Continue</Action>
      </Actions>
    </Wrapper>
  );
}

NoMedication.propTypes = {
  stroke: PropTypes.number.isRequired,
};

const mapStateToProps = ({ risks: { stroke } }) => ({ stroke });

export default connect(mapStateToProps)(NoMedication);
