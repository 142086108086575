const drugs = [
  {
    name: 'No Treatment',
    doseFrequency: 'Not applicable',
    foodAndDrugInteractions: 'None',
    bloodTestingRequirement: 'None',
    commonSideEffects: 'None',
    cost: '~ $0',
    reversibility: 'Not applicable',
    chanceOfStroke: {
      0: '1/100',
      1: '2/100',
      2: '3/100',
      3: '5/100',
      4: '7/100',
      5: '11/100',
      6: '14/100',
      7: '15/100',
      8: '15/100',
      9: '17/100',
    },
    chanceOfBleeding: {
      0: '1/100',
      1: '1/100',
      2: '3/100',
      3: '4/100',
      4: '4/100',
      5: '4/100',
      6: '4/100',
      7: '4/100',
      8: '4/100',
      9: '4/100',
    },
  },
  {
    name: 'warfarin (Coumadin®)',
    doseFrequency: '1x daily',
    foodAndDrugInteractions: 'Many',
    bloodTestingRequirement: 'Monthly (or more)',
    commonSideEffects: 'None',
    cost: '~ $5',
    reversibility: 'Yes',
    chanceOfStroke: {
      0: '< 1/100',
      1: '1/100',
      2: '1/100',
      3: '2/100',
      4: '3/100',
      5: '4/100',
      6: '5/100',
      7: '6/100',
      8: '6/100',
      9: '7/100',
    },
    chanceOfBleeding: {
      0: '3/100',
      1: '3/100',
      2: '6/100',
      3: '8/100',
      4: '8/100',
      5: '8/100',
      6: '8/100',
      7: '8/100',
      8: '8/100',
      9: '8/100',
    },
  },
  {
    name: 'apixaban (Eliquis®)',
    doseFrequency: '2x daily',
    foodAndDrugInteractions: 'Few',
    bloodTestingRequirement: 'Annually',
    commonSideEffects: 'None',
    cost: '~ $25',
    reversibility: 'No',
    chanceOfStroke: {
      0: '< 1/100',
      1: '1/100',
      2: '1/100',
      3: '1/100',
      4: '2/100',
      5: '3/100',
      6: '4/100',
      7: '5/100',
      8: '5/100',
      9: '5/100',
    },
    chanceOfBleeding: {
      0: '2/100',
      1: '2/100',
      2: '4/100',
      3: '6/100',
      4: '6/100',
      5: '6/100',
      6: '6/100',
      7: '6/100',
      8: '6/100',
      9: '6/100',
    },
  },
  {
    name: 'dabigatran 100 mg (Pradaxa®)',
    doseFrequency: '2x daily',
    foodAndDrugInteractions: 'Few',
    bloodTestingRequirement: 'Annually',
    commonSideEffects: 'Upset stomach',
    cost: '~ $75',
    reversibility: 'Yes',
    chanceOfStroke: {
      0: '< 1/100',
      1: '1/100',
      2: '1/100',
      3: '2/100',
      4: '2/100',
      5: '4/100',
      6: '5/100',
      7: '5/100',
      8: '5/100',
      9: '6/100',
    },
    chanceOfBleeding: {
      0: '2/100',
      1: '2/100',
      2: '4/100',
      3: '7/100',
      4: '7/100',
      5: '7/100',
      6: '7/100',
      7: '7/100',
      8: '7/100',
      9: '7/100',
    },
  },
  {
    name: 'dabigatran 150 mg (Pradaxa®)',
    doseFrequency: '2x daily',
    foodAndDrugInteractions: 'Few',
    bloodTestingRequirement: 'Annually',
    commonSideEffects: 'Upset stomach',
    cost: '~ $100',
    reversibility: 'Yes',
    chanceOfStroke: {
      0: '< 1/100',
      1: '< 1/100',
      2: '1/100',
      3: '1/100',
      4: '2/100',
      5: '3/100',
      6: '3/100',
      7: '4/100',
      8: '4/100',
      9: '4/100',
    },
    chanceOfBleeding: {
      0: '3/100',
      1: '3/100',
      2: '5/100',
      3: '8/100',
      4: '8/100',
      5: '8/100',
      6: '8/100',
      7: '8/100',
      8: '8/100',
      9: '8/100',
    },
  },
  {
    name: 'edoxaban 60 mg (Lixiana®)',
    doseFrequency: '1x daily',
    foodAndDrugInteractions: 'Few',
    bloodTestingRequirement: 'Annually',
    commonSideEffects: 'None',
    cost: '~ $90',
    reversibility: 'No',
    chanceOfStroke: {
      0: '< 1/100',
      1: '1/100',
      2: '1/100',
      3: '2/100',
      4: '2/100',
      5: '4/100',
      6: '5/100',
      7: '5/100',
      8: '5/100',
      9: '6/100',
    },
    chanceOfBleeding: {
      0: '2/100',
      1: '2/100',
      2: '4/100',
      3: '7/100',
      4: '7/100',
      5: '7/100',
      6: '7/100',
      7: '7/100',
      8: '7/100',
      9: '7/100',
    },
  },
  {
    name: 'rivaroxaban (Xarelto®)',
    doseFrequency: '1x daily',
    foodAndDrugInteractions: 'Few',
    bloodTestingRequirement: 'Annually',
    commonSideEffects: 'None',
    cost: '~ $90',
    reversibility: 'No',
    chanceOfStroke: {
      0: '< 1/100',
      1: '1/100',
      2: '1/100',
      3: '2/100',
      4: '2/100',
      5: '4/100',
      6: '5/100',
      7: '5/100',
      8: '5/100',
      9: '6/100',
    },
    chanceOfBleeding: {
      0: '3/100',
      1: '3/100',
      2: '6/100',
      3: '8/100',
      4: '8/100',
      5: '8/100',
      6: '8/100',
      7: '8/100',
      8: '8/100',
      9: '8/100',
    },
  },
];

export default drugs;
