import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { Actions, PaddedWrapper, Centered, Grow } from './styled/Wrappers';
import { Button } from './styled/Buttons';
import { Text } from './styled/Typography';
import * as Question from './styled/Question';
import RiskChart, { Legend } from './RiskChart';
import fadeIn from './styled/Animations';

const Bullet = styled.span`
  border-radius: 50%;
  display: inline-block;
  height: 16px;
  margin-right: 7px;
  flex: 0 0 16px;
`;

const Wrapper = styled(Grow)`
  ${fadeIn}
`;

function Risk({
  onContinueRoute,
  type,
  scoreInWords,
  score,
  percentChance,
  totalChance,
  chanceDescriptor,
}) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <PaddedWrapper>
        <Question.Ribbon>
          <Question.RibbonText>
            Your score on the {type.toLowerCase()}-related questions is <strong>{score}</strong> out
            of <strong>{totalChance}</strong>. This is a {scoreInWords} score.
          </Question.RibbonText>
        </Question.Ribbon>
      </PaddedWrapper>
      <Centered>
        <RiskChart
          totalChance={totalChance}
          percentChance={percentChance}
          type={type}
          caption={`${type}-related questions score`}
        />
        <Legend>
          <Text>
            <Bullet style={{ backgroundColor: '#ABC735' }} />
            Low chance of {chanceDescriptor}
          </Text>
          <Text>
            <Bullet style={{ backgroundColor: '#FFC000' }} />
            Moderate chance of {chanceDescriptor}
          </Text>
          <Text>
            <Bullet style={{ backgroundColor: '#E2583A' }} />
            High chance of {chanceDescriptor}
          </Text>
        </Legend>
      </Centered>
      <Actions>
        <Button onClick={() => navigate(onContinueRoute)}>Continue</Button>
      </Actions>
    </Wrapper>
  );
}

Risk.propTypes = {
  chanceDescriptor: PropTypes.string.isRequired,
  onContinueRoute: PropTypes.string.isRequired,
  percentChance: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  scoreInWords: PropTypes.string.isRequired,
  totalChance: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default Risk;
