import React from 'react';
import styled from 'styled-components';

import Nav from './Nav';
import ProgressBar from './ProgressBar';
import { Actions, Container, PaddedWrapper } from './styled/Wrappers';
import { Link } from './styled/Buttons';
import { H2, Text } from './styled/Typography';
import { InfoRibbon, BlueBorderedText } from './styled/InfoRibbon';
import theme from './styled/theme';

const Number = styled(Text)`
  color: ${theme.colours.active};
  font-size: 30px;
  margin-top: 18px;
  font-weight: 600;
  left: -25px;
  position: absolute;
  top: 0;
`;

function About() {
  return (
    <>
      <Nav />
      <ProgressBar step={1} />
      <Container>
        <PaddedWrapper>
          <H2>
            About the <br />
            <strong> RAPID AFib Decision Aid</strong>
          </H2>
          <InfoRibbon>
            <Number>1</Number>
            <BlueBorderedText>
              You will be asked to answer some health-related questions, which will require you to
              know a little bit about your medical history. These questions will help you determine
              your chance of stroke and bleeding.
            </BlueBorderedText>
          </InfoRibbon>
          <InfoRibbon>
            <Number>2</Number>
            <BlueBorderedText>
              You will be able to learn about the different oral anticoagulants by comparing them
              side-by-side. This will help you decide which, if any, oral anticoagulants are best
              for you.
            </BlueBorderedText>
          </InfoRibbon>
          <InfoRibbon>
            <Number>3</Number>
            <BlueBorderedText>
              When you finish going through the <strong>RAPID AFib Decision Aid</strong>, you can
              get a summary that you can save and then discuss with your doctor.
            </BlueBorderedText>
          </InfoRibbon>
          <Actions>
            <Link to="/stroke">Continue</Link>
          </Actions>
        </PaddedWrapper>
      </Container>
    </>
  );
}

export default About;
