import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import theme from './theme';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colours.active};
  border-radius: 6px;
  color: #ffffff;
  font-family: ${theme.fontFamily};
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  height: 52px;
  max-width: 100%;
  width: 170px;
  border: none;
  padding: 16px;

  &:active,
  &:hover {
    opacity: 0.9;
  }
`;

export const CircleButton = styled.button`
  align-items: center;
  border: 0;
  border-radius: 50%;
  display: flex;
  height: 22px;
  justify-content: center;
  width: 22px;
  font-weight: 600;
  margin-right: 13px;
  outline: none;
  padding: 0;
`;

export const Link = styled(RouterLink)`
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  font-family: ${theme.fontFamily};
  text-align: center;
  max-width: 325px;
  padding: 11px 16px;
  border-radius: 6px;
  background: ${theme.colours.active};
  min-width: 170px;

  &:active,
  &:hover {
    opacity: 0.9;
  }
`;

export const TextLink = styled(Button)`
  background: none;
  border: none;
  box-shadow: none;
  color: ${theme.colours.active};
  font-size: 16px;
  font-weight: 600;
`;

export const InlineLink = styled.a`
  background: transparent;
  color: ${theme.colours.active};
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
`;
