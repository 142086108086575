import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { Grow, Container, Actions, PaddedWrapper } from './styled/Wrappers';
import { Button } from './styled/Buttons';
import { H1, Text } from './styled/Typography';
import Nav from './Nav';
import { understandDisclaimer } from '../reducers/ui';
import { InfoRibbon, BlueBorderedText } from './styled/InfoRibbon';

const Title = styled(H1)`
  font-size: 16px;
`;

function Disclaimer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Nav />
      <Container>
        <Grow>
          <PaddedWrapper>
            <Title>Disclaimer</Title>
            <InfoRibbon>
              <BlueBorderedText>
                The <strong>RAPID AFib Decision Aid</strong> is not meant to act as a substitute for
                medical advice from your doctor.
              </BlueBorderedText>
              <Text>
                You may find information in the <strong>RAPID AFib Decision Aid</strong> that does
                not apply to you or that could be inappropriate for your medical condition.
              </Text>
              <Text>
                If you have any questions about the appropriateness of the{' '}
                <strong>RAPID AFib Decision Aid </strong>
                for your medical condition, talk with your doctor.
              </Text>
            </InfoRibbon>
          </PaddedWrapper>
          <Actions>
            <Button
              onClick={() => {
                dispatch(understandDisclaimer());
                navigate('/about');
              }}
            >
              I Understand
            </Button>
          </Actions>
        </Grow>
      </Container>
    </>
  );
}

export default Disclaimer;
