export const STROKE_CHANCE = {
  noMedication: {
    0: 1,
    1: 2,
    2: 3,
    3: 5,
    4: 7,
    5: 11,
    6: 14,
    7: 15,
    8: 15,
    9: 17,
  },
  medication: {
    0: 1,
    1: 1,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    7: 6,
    8: 6,
    9: 7,
  },
};

export const BLEED_CHANCE = {
  noMedication: {
    0: 1,
    1: 1,
    2: 3,
    3: 4,
    4: 4,
    5: 4,
    6: 4,
    7: 4,
    8: 4,
    9: 4,
  },
  medication: {
    0: 3,
    1: 3,
    2: 6,
    3: 8,
    4: 8,
    5: 8,
    6: 8,
    7: 8,
    8: 8,
    9: 8,
  },
};
