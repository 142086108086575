/*
 * ACTIONS
 */
const UNDERSTAND_DISCLAIMER = 'Accepted Disclaimer';

export const SET_AGE = 'Set Age';
const SET_STROKE = 'Diagnosed with Stroke';
const SET_UNSURE = 'Unsure of Conditions';

/*
 * ACTION CREATORS
 */
export const understandDisclaimer = () => ({
  type: UNDERSTAND_DISCLAIMER,
});

export const setUnsure = () => ({
  type: SET_UNSURE,
});

export const setAge = (age) => ({
  type: SET_AGE,
  age,
});

export const setStroke = (stroke) => ({
  type: SET_STROKE,
  stroke,
});

/*
 * REDUCERS
 */
const defaultState = {
  understandDisclaimer: false,
  age: null,
  stroke: false,
  unsure: false,
};

// eslint-disable-next-line default-param-last
const main = (state = defaultState, action) => {
  switch (action.type) {
    case SET_UNSURE:
      return {
        ...state,
        unsure: true,
      };
    case SET_AGE:
      return {
        ...state,
        age: action.age,
      };
    case SET_STROKE:
      return {
        ...state,
        stroke: action.stroke,
      };
    case UNDERSTAND_DISCLAIMER:
      return {
        ...state,
        understandDisclaimer: true,
      };
    default:
      return state;
  }
};

export default main;
