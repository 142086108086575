import React from 'react';

import Nav from './Nav';
import { H2, Text } from './styled/Typography';
import { Container, Grow } from './styled/Wrappers';
import { InlineLink } from './styled/Buttons';

function ThankYou() {
  return (
    <>
      <Nav />
      <Container>
        <H2>
          <strong>Thank you for using the RAPID AFib Decision Aid!</strong>
        </H2>
        <Grow>
          <Text>
            The <strong>RAPID AFib Decision Aid</strong> uses{' '}
            <InlineLink>
              CHA<sub>2</sub>DS<sub>2</sub>-VASc
            </InlineLink>{' '}
            and
            <InlineLink> HAS-BLED</InlineLink> scoring to determine your chances of stroke and
            bleeding, respectively.
          </Text>
          <Text>
            The <strong>RAPID AFib Decision Aid</strong> was developed by{' '}
            <InlineLink href="https://www.tactica.ca">Tactica Interactive</InlineLink>.
          </Text>
          <Text>
            Funding was provided by the{' '}
            <InlineLink href="https://www.heartandstroke.ca/">
              Heart &amp; Stroke Foundation of Canada
            </InlineLink>
            .
          </Text>
        </Grow>
      </Container>
    </>
  );
}

export default ThankYou;
