/*
 * ACTIONS
 */
export const TOGGLE = 'TOGGLE';

/*
 * ACTION CREATORS
 */
export const toggle = (drug) => ({
  type: TOGGLE,
  drug,
});

/*
 * REDUCERS
 */
const defaultState = [];

// eslint-disable-next-line default-param-last
const main = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE:
      if (state.indexOf(action.drug) === -1) {
        return [...state, action.drug];
      }

      return [...state].filter((drug) => drug !== action.drug);
    default:
      return state;
  }
};

export default main;
