import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    document
      .querySelector('#root > section')
      ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }, [location]);

  return children;
};

export default ScrollToTop;
