import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import theme from '../styled/theme';
import unhappy from '../../images/unhappy.svg';
import unhappyRed from '../../images/unhappy_red.svg';
import { Hr, H4, Ul, ListItem } from '../styled/Typography';
import { Button, Link } from '../styled/Buttons';
import { Matrix, Smiley, Summary, Legend } from '../styled/Matrix';
import { Actions, PaddedWrapper } from '../styled/Wrappers';
import { InfoRibbon, BlueBorderedText } from '../styled/InfoRibbon';
import { STROKE_CHANCE, BLEED_CHANCE } from '../../config/stats';
import fadeIn from '../styled/Animations';

const Tabs = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
`;

const Tab = styled(Button)`
  background-color: ${theme.colours.active};
  border-radius: 4px 0 0 4px;
  color: #8f99ad;
  flex: 1;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 20px;

  & ~ button {
    border-radius: 0 4px 4px 0;
  }

  &.active {
    color: #fff;
  }
`;

const Wrapper = styled.section`
  ${fadeIn}
`;

const Switch = styled.label`
  bottom: 0;
  display: inline-block;
  height: 21px;
  left: 50%;
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 40px;
  z-index: 5;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background: #566583;
    transition: 0.4s;
  }

  span:before {
    position: absolute;
    content: '';
    height: 21px;
    width: 20px;
    left: 0;
    bottom: 0;
    transition: 0.4s;
    border-radius: 50%;
    background-image: linear-gradient(155.8deg, #27b7cf -12.9%, #1180a1 85.15%);
  }

  input:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + span:before {
    transform: translateX(26px);
  }
`;

const total = 100;
function NoMedication({ bleeding, stroke }) {
  const [noMedication, setMedication] = useState(true);
  const bleedNoMed = parseInt(total * (BLEED_CHANCE.noMedication[bleeding] / 100), 10);
  const bleedMed = parseInt(total * (BLEED_CHANCE.medication[bleeding] / 100), 10);
  const strokeNoMed = parseInt(total * (STROKE_CHANCE.noMedication[stroke] / 100), 10);
  let strokeChance = parseInt(total * (STROKE_CHANCE.medication[stroke] / 100), 10);

  if (noMedication) {
    strokeChance = strokeNoMed;
  }

  let bleedChance = bleedMed;
  if (noMedication) {
    bleedChance = bleedNoMed;
  }

  const unaffected = total - strokeChance - bleedChance;

  return (
    <Wrapper>
      <Tabs>
        <Tab onClick={() => setMedication(true)} className={noMedication ? 'active' : ''}>
          Without an Oral Anticoagulant
        </Tab>
        <Switch>
          <input type="checkbox" checked={!noMedication} onChange={() => {}} />
          <span
            tabIndex={0}
            onClick={() => setMedication(!noMedication)}
            onKeyDown={() => setMedication(!noMedication)}
            aria-label="Toggle Medication"
            aria-checked={!noMedication}
            role="switch"
          />
        </Switch>
        <Tab onClick={() => setMedication(false)} className={!noMedication ? 'active' : ''}>
          With an Oral Anticoagulant
        </Tab>
      </Tabs>
      <PaddedWrapper>
        <InfoRibbon>
          <BlueBorderedText>
            Without an oral anticoagulant,{' '}
            <strong>
              {strokeChance} {strokeNoMed > 1 ? 'people' : 'person'} in a group of {total} people
              like you
            </strong>{' '}
            will probably have a <strong>stroke</strong> in the next year and{' '}
            <strong>
              {bleedChance} out of those {total}
            </strong>{' '}
            will probably have a <strong>serious bleeding problem</strong>.
          </BlueBorderedText>
        </InfoRibbon>
      </PaddedWrapper>
      <Matrix>
        {[...Array(strokeChance)].map((_, i) => (
          <Smiley key={`pc${i}`} imgSrc={unhappy} />
        ))}
        {[...Array(unaffected)].map((_, i) => (
          <Smiley key={`ua${i}`} />
        ))}
        {[...Array(bleedChance)].map((_, i) => (
          <Smiley key={`pcb${i}`} imgSrc={unhappyRed} />
        ))}
      </Matrix>
      <Hr />
      <PaddedWrapper>
        <Legend>
          <H4>Legend</H4>
          <Ul>
            <ListItem>
              <Smiley imgSrc={unhappy} />
              They are likely to have a stroke
            </ListItem>
            <ListItem>
              <Smiley imgSrc={unhappyRed} />
              They are likely to have a serious bleeding problem
            </ListItem>
            <ListItem>
              <Smiley />
              They are likely to not have a stroke
            </ListItem>
          </Ul>
        </Legend>
      </PaddedWrapper>
      <Hr />
      <Summary>
        {noMedication &&
          `In other words, without an oral anticoagulant, your chance of having a stroke is ${strokeChance}% and your chance of a serious bleeding problem is ${bleedChance}% in the next year.`}
        {!noMedication &&
          `In other words, by taking an oral anticoagulant, you can reduce your chance of having a stroke from ${strokeNoMed}% to ${strokeChance}% in the next year, but your chance of having a serious bleeding problem is ${bleedChance}%.`}
      </Summary>
      <Actions>
        <Link to="/bleeding/medication">Continue</Link>
      </Actions>
    </Wrapper>
  );
}

NoMedication.propTypes = {
  bleeding: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
};

const mapStateToProps = ({ risks: { bleeding, stroke } }) => ({ bleeding, stroke });

export default connect(mapStateToProps)(NoMedication);
