import ReactGA from 'react-ga4';
import { SET_AGE } from './reducers/ui';
import { ADD } from './reducers/answers';
import { TOGGLE } from './reducers/favourites';

// ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
ReactGA.initialize('G-ZN94C2Z34S', {
  debug: false,
  titleCase: false,
  anonymizeIp: true,
  testMode: process.env.NODE_ENV === 'test',
  gaOptions: {
    cookieFlags: 'SameSite=None;Secure',
  },
});

const analytics = () => (next) => (action) => {
  const { type } = action;
  ReactGA.set({ anonymizeIp: true });

  switch (type) {
    case ADD: {
      const { questionType } = action;
      ReactGA.event({
        category: 'Redux',
        action: `Completed ${questionType} Questions`,
      });
      break;
    }
    case SET_AGE: {
      const {
        age: { answer },
      } = action;
      ReactGA.event({
        category: 'Redux',
        action: `${SET_AGE} ${answer}`,
      });
      break;
    }
    case TOGGLE: {
      const { drug } = action;
      ReactGA.event({
        category: 'Redux',
        action: `Toggled Drug ${drug}`,
      });
      break;
    }
    default: {
      ReactGA.event({
        category: 'Redux',
        action: type,
      });
    }
  }

  return next(action);
};

export default analytics;
