import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import DRUGS from '../config/drugs';
import { STROKE_CHANCE, BLEED_CHANCE } from '../config/stats';
import Nav from './Nav';
import Printable from './Printable';
import ProgressBar from './ProgressBar';
import checkbox from '../images/checkbox.svg';
import print from '../images/print.svg';
import theme from './styled/theme';
import unchecked from '../images/checkbox-unchecked.svg';
import { AGE_QUESTION } from '../config/questions';
import { Button, InlineLink, TextLink } from './styled/Buttons';
import { Actions, Container, PaddedWrapper } from './styled/Wrappers';
import { H2, Text } from './styled/Typography';
import { InfoRibbon } from './styled/InfoRibbon';
import { Recommendation } from './stroke/Medication';
import { scoreInWords as bleedingScoreInWords } from './bleeding/Risk';
import { scoreInWords as strokeScoreInWords } from './stroke/Risk';
import pdf from '../RAPID AFib Decision Aid Notes v1.1 Sept 2023.pdf';

function Summary({ answers, favourites, risks: { stroke, bleeding }, unsure }) {
  const total = 100;
  const navigate = useNavigate();
  const bleedNoMed = parseInt(total * (BLEED_CHANCE.noMedication[bleeding] / 100), 10);
  const strokeNoMed = parseInt(total * (STROKE_CHANCE.noMedication[stroke] / 100), 10);
  const printableContent = (
    <>
      <Header>
        <H2>
          Your <strong>RAPID AFib Summary</strong>
        </H2>

        <Button
          className="print-button"
          onClick={() => {
            document.title = 'RAPID-AFib-Summary';
            window.print();
          }}
        >
          <img src={print} alt="print" />
          Print
        </Button>
      </Header>
      <Text>
        The RAPID AFib Decision Aid uses CHA<sub>2</sub>DS<sub>2</sub>-VASc and HAS-BLED scoring to
        determine your chances of stroke and bleeding, respectively. Recommendations for oral
        anticoagulation are based on the Canadian Cardiovascular Society&apos;s simplified algorithm
        (CHADS-65).
      </Text>
      <InfoRibbon>
        <Text>
          Do you have one or more of the following conditions: artificial heart valve, recent
          coronary procedures and/or end-stage kidney disease?
        </Text>
        <Checkboxes>
          <Text>
            <img src={unchecked} alt="Unchecked checkbox" />
            Yes
          </Text>
          <Text>
            <img src={unsure ? unchecked : checkbox} alt="Checked checkbox" />
            No
          </Text>
          <Text>
            <img src={unsure ? checkbox : unchecked} alt="Unchecked checkbox" />
            Not Sure
          </Text>
        </Checkboxes>
      </InfoRibbon>
      <H2>Your Chance of a Stroke</H2>
      <ScoreRibbon>
        <Text>Stroke Score</Text>
        <header>
          <Score $colour="#e2583a">{stroke}</Score>
          <ScoreText>
            <H3>
              This is a <span>{strokeScoreInWords(stroke)}</span> Score
            </H3>
            <Text>{strokeNoMed}% chance of a stroke in the next year</Text>
          </ScoreText>
        </header>
        <Text>Risk Factors</Text>
        <table>
          <tbody>
            {answers.stroke.map((a, i) => (
              <tr key={`stroke_${i}`}>
                <Question dangerouslySetInnerHTML={{ __html: a.question }} />
                <td>
                  <span>
                    {(() => {
                      if (a.question === AGE_QUESTION) {
                        switch (a.answer) {
                          case '64 Years or Younger': {
                            return '< 64';
                          }
                          case '65 to 74 Years': {
                            return '65-74';
                          }
                          case '75 Years or Older': {
                            return '> 75';
                          }
                          default:
                            // eslint-disable-next-line no-console
                            console.error('Unexpected age category');
                        }
                      }

                      return a.answer === "I'm Not Sure" ? 'Not Sure' : a.answer;
                    })()}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScoreRibbon>
      <Recommendation stroke={stroke} />
      <H2>Your Chance of Bleeding</H2>
      <ScoreRibbon>
        <Text>Bleeding Score</Text>
        <header>
          <Score $colour="#ffc000">{bleeding}</Score>
          <ScoreText>
            <H3>
              This is a <span>{bleedingScoreInWords(bleeding)}</span> Score
            </H3>
            <Text>{bleedNoMed}% chance of a bleeding in the next year</Text>
          </ScoreText>
        </header>
        <Text>Risk Factors</Text>
        <table>
          <tbody>
            {answers.bleeding.map((a, i) => (
              <tr key={`stroke_${i}`}>
                <Question dangerouslySetInnerHTML={{ __html: a.question }} />
                <td>
                  <span>{a.answer === "I'm Not Sure" ? 'Not Sure' : a.answer}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScoreRibbon>
      <H2>Your preferred treatments</H2>
      <ScoreRibbon>
        <table className="preferred-treatments">
          <tbody>
            {DRUGS.map((drug, i) => (
              <tr key={`drug_${i}`}>
                <td>{drug.name}</td>
                <td>
                  <img src={favourites.includes(drug.name) ? checkbox : unchecked} alt="checkbox" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScoreRibbon>
      <H2>Next steps</H2>
      <InfoRibbon>
        <Text>
          If you haven&apos;t already, schedule an appointment with your doctor to discuss these
          results.
        </Text>
        <Text>
          For further information about atrial fibrillation, consult:
          <br />
          <br />
          <InlineLink
            href="https://www.heartandstroke.ca/heart/conditions/atrial-fibrillation"
            target="_blank"
          >
            https://www.heartandstroke.ca/heart/conditions/atrial-fibrillation
          </InlineLink>
          <br />
          <br />
          <InlineLink href={pdf} target="_blank">
            RAPID AFib Decision Aid Notes and Further Information
          </InlineLink>
        </Text>
      </InfoRibbon>
    </>
  );

  return (
    <>
      <Nav />
      <ProgressBar step={5} />
      <Container>
        <div>
          {printableContent}
          <Printable>
            <PrintStyle>{printableContent}</PrintStyle>
          </Printable>
          <Actions>
            <TextLink
              onClick={() => {
                navigate(-1);
              }}
            >
              Previous
            </TextLink>
            <Button
              style={{ marginTop: 20 }}
              onClick={() => {
                navigate('/thankyou');
              }}
            >
              Finish
            </Button>
          </Actions>
        </div>
      </Container>
    </>
  );
}

const Question = styled.td`
  font-size: 0;

  strong {
    display: block;
    font-size: 14px;
    max-width: 220px;
    font-weight: normal;

    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

const ScoreRibbon = styled(InfoRibbon)`
  header {
    display: flex;
    width: 232px;
  }

  & > p {
    font-weight: 500;
    margin: 34px 0 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    tr td:last-child {
      width: 66px;
    }

    tr {
      -webkit-print-color-adjust: exact;
      background-color: #eceff4;
    }

    tr:nth-child(even) {
      -webkit-print-color-adjust: exact;
      background-color: ${theme.colours.white};
      td {
        padding: 10px 0 10px 5px;
      }
      td:last-child {
        -webkit-print-color-adjust: exact;
        background-color: ${theme.colours.white};
        padding: 0;

        span {
          -webkit-print-color-adjust: exact;
          background-color: ${theme.colours.active};
          color: ${theme.colours.white};
          display: block;
          margin: 8px 0;
          padding: 4px 0;
          text-align: center;
        }
      }
    }

    td {
      font-family: ${theme.fontFamily};
      padding: 5px 0 5px 5px;
    }

    &:not(.preferred-treatments) {
      td:last-child {
        -webkit-print-color-adjust: exact;
        background-color: ${theme.colours.active};
        color: ${theme.colours.white};
        padding: 5px 0;
        text-align: center;
      }
    }

    &.preferred-treatments {
      tr:nth-child(odd) {
        td {
          padding: 0 0 0 5px;
        }
      }

      td:last-child {
        img {
          display: block;
          float: right;
          height: 22px;
          width: 22px;
        }
      }
    }
  }
`;

const ScoreText = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0 0 3px 0;
  }

  p {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
  }
`;

const H3 = styled.h3`
  font-family: ${theme.fontFamily};
  font-size: 16px;

  span {
    text-transform: capitalize;
  }
`;

const Score = styled.div`
  align-items: center;
  -webkit-print-color-adjust: exact;
  background-color: ${(props) => props.$colour};
  color: #fff;
  display: flex;
  flex-shrink: 0;
  font-size: 43px;
  font-weight: bold;
  height: 54px;
  justify-content: center;
  margin-right: 12px;
  width: 54px;
`;

const Checkboxes = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 280px;

  p {
    align-items: center;
    display: flex;

    img {
      margin-right: 5px;
    }

    @media (min-width: 370px) {
      img {
        margin-right: 15px;
      }
    }
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }

  button {
    justify-content: space-around;
    max-width: 114px;
  }
`;

const PrintStyle = styled(PaddedWrapper)`
  margin: 50px;

  .print-button {
    display: none;
  }
`;

Summary.propTypes = {
  answers: PropTypes.shape({
    bleeding: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string,
      })
    ),
    stroke: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string,
      })
    ),
  }).isRequired,
  favourites: PropTypes.arrayOf(PropTypes.string).isRequired,
  risks: PropTypes.shape({
    bleeding: PropTypes.number,
    stroke: PropTypes.number,
  }).isRequired,
  unsure: PropTypes.bool.isRequired,
};

function mapStateToProps({ answers, favourites, risks, ui: { unsure } }) {
  return {
    answers,
    favourites,
    risks,
    unsure,
  };
}

export default connect(mapStateToProps)(Summary);
