import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { Actions, PaddedWrapper } from '../styled/Wrappers';
import { TextLink, Button } from '../styled/Buttons';
import { Wrapper } from '../styled/Question';
import { Text } from '../styled/Typography';
import { update } from '../../reducers/risks';
import { add } from '../../reducers/answers';

const WrapperWithGrow = styled(Wrapper)`
  ${({ $state }) => {
    return $state === 'exiting' ? 'display: none;' : '';
  }}
`;

function Outro({ answers, score, onBack, state, previous }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <WrapperWithGrow $previous={previous} $state={state}>
      <PaddedWrapper>
        <Text>
          Please check that your answers are correct before continuing with the{' '}
          <strong>RAPID AFib Decision Aid.</strong>
        </Text>
      </PaddedWrapper>
      <Actions>
        <TextLink onClick={onBack}>Previous</TextLink>
        <Button
          onClick={() => {
            dispatch(add({ answers, questionType: 'bleeding' }));
            dispatch(update({ bleeding: score }));
            navigate('/bleeding/risk');
          }}
        >
          Continue
        </Button>
      </Actions>
    </WrapperWithGrow>
  );
}

Outro.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  onBack: PropTypes.func.isRequired,
  previous: PropTypes.bool.isRequired,
  score: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
};

export default compose(connect())(Outro);
