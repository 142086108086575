import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import './App.css';
import store from './store';
import Home from './components/Home';
import Gate from './components/Gate';
import Disclaimer from './components/Disclaimer';
import About from './components/About';
import Stroke from './components/Stroke';
import Bleeding from './components/Bleeding';
import Options from './components/Options';
import ThankYou from './components/ThankYou';
import Summary from './components/Summary';
import AidNotRight from './components/AidNotRight';
import ScrollToTop from './components/ScrollToTop';
import background from './images/background.jpg';

function ProtectedRoute() {
  const { understandDisclaimer } = useSelector((state) => state.ui);

  if (understandDisclaimer || process.env.NODE_ENV === 'development') {
    return <Outlet />;
  }

  return <Navigate to="/disclaimer" />;
}

class App extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
    document.body.style.overflowY = 'scroll';
    document.body.style.background = `url(${background})`;
    document.body.style.backgroundRepeat = `no-repeat`;
    document.body.style.backgroundSize = `cover`;
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/gate" element={<Gate />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/aidnotright" element={<AidNotRight />} />
              <Route path="/" element={<ProtectedRoute />}>
                <Route path="/about" element={<About />} />
                <Route path="/stroke/*" element={<Stroke />} />
                <Route path="/bleeding/*" element={<Bleeding />} />
                <Route path="/options/*" element={<Options />} />
                <Route path="/summary" element={<Summary />} />
                <Route path="/thankyou" element={<ThankYou />} />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
