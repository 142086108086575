import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toggle } from '../../reducers/favourites';

import theme from '../styled/theme';
import { CircleButton } from '../styled/Buttons';

const Button = styled(CircleButton)`
  background: #ffffff;
  color: #898989;
  flex: 0 0 22px;

  &.active {
    color: ${theme.colours.warning};
  }

  &:active,
  &:hover {
    color: ${theme.colours.warning};
    opacity: 0.9;
  }
`;

function Favourite({ dispatch, drug, isFavourited }) {
  return (
    <Button onClick={() => dispatch(toggle(drug))} className={isFavourited ? 'active' : ''}>
      &#9733;
    </Button>
  );
}

Favourite.propTypes = {
  dispatch: PropTypes.func.isRequired,
  drug: PropTypes.string.isRequired,
  isFavourited: PropTypes.bool.isRequired,
};

function mapStateToProps({ favourites }, { drug }) {
  const isFavourited = favourites.indexOf(drug) > -1;

  return {
    isFavourited,
  };
}

export default connect(mapStateToProps)(Favourite);
