import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { Text } from './styled/Typography';
import house from '../images/house.svg';
import theme from './styled/theme';

const Title = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
}
`;

const Link = styled(RouterLink)`
  height: 16px;
  position: relative;
  width: 15px;

  &:hover,
  &:active {
    img {
      bottom: 5px;
      transition: bottom 0.3s ease;
    }
  }

  img {
    bottom: 0;
    position: absolute;
    transition: bottom 0.3s ease;
  }
`;

const EmptyDiv = styled.div`
  align-items: center;
  background: ${theme.colours.background};
  box-shadow: 0 0.5px 1px rgba(236, 245, 250, 0.5);
  height: 60px;
  margin: auto;
  max-width: 100%;
  width: 100%;
`;

const Navigation = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 60px;
  margin: auto;
  max-width: ${theme.navWidth};
  padding: 10px 18px;
  width: 100%;

  a {
    position: absolute;
  }

  p {
    flex-grow: 1;
    text-align: center;
  }
`;

function Nav() {
  return (
    <EmptyDiv>
      <Navigation>
        <Link to="/">
          <img src={house} alt="Return to start" />
        </Link>
        <Title>RAPID AFib Decision Aid</Title>
      </Navigation>
    </EmptyDiv>
  );
}

export default Nav;
