import styled from 'styled-components';
import theme from './theme';

export const Bold = styled.strong`
  font-weight: 600;
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
`;

export const Hr = styled.hr`
  background: #dee2e5;
  border: 0;
  width: 100%;
  margin: 30px 0;
  height: 1px;
`;

export const ListItem = styled.li`
  color: ${theme.colours.body};
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Text = styled.p`
  color: ${theme.colours.body};
  font-family: ${theme.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const H1 = styled.h1`
  color: ${theme.colours.body};
  font-family: ${theme.fontFamily};
  font-size: 25px;
  font-weight: 500;
  line-height: 26px;
`;

export const H2 = styled.h2`
  color: ${theme.colours.body};
  font-family: ${theme.fontFamily};
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
`;

export const H4 = styled.h4`
  color: ${theme.colours.body};
  font-family: ${theme.fontFamily};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`;
