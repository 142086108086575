import React from 'react';
import { Actions, PaddedWrapper } from '../styled/Wrappers';
import { Link } from '../styled/Buttons';
import { InfoRibbon, BlueBorderedText } from '../styled/InfoRibbon';
import tabsImage from '../../images/tabs_screenshot.png';
import Screenshot from '../styled/Screenshot';

function Info() {
  return (
    <>
      <PaddedWrapper>
        <InfoRibbon>
          <BlueBorderedText>
            You will want to consider the benefits of reducing your chance of stroke by taking an
            oral anticoagulant versus your increased chance of bleeding from taking that same oral
            anticoagulant.
          </BlueBorderedText>
        </InfoRibbon>
        <InfoRibbon>
          <BlueBorderedText>
            Use the toggle on the next page to help visualize the benefits of reducing your chance
            of stroke by taking an oral anticoagulant versus your increased chance of bleeding from
            taking that same oral anticoagulant.
          </BlueBorderedText>
          <Screenshot src={tabsImage} alt="Screenshot of the tabs" />
        </InfoRibbon>
      </PaddedWrapper>
      <Actions>
        <Link to="/bleeding/no-medication">Continue</Link>
      </Actions>
    </>
  );
}

export default Info;
