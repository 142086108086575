import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem } from '../styled/Typography';

const SubmenuContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 2;

  .slide-enter {
    transform: translateY(-100%);
    transition: 0.3s cubic-bezier(0, 1, 0.5, 1);

    &.slide-enter-active {
      transform: translateY(0%);
    }
  }

  .slide-leave {
    transform: translateY(0%);
    transition: 0.3s ease-in-out;

    &.slide-leave-active {
      transform: translateY(-100%);
    }
  }

  .nav__submenu {
    background: #eee;
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};
    font-weight: 300;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-transform: none;
    z-index: 1;
  }

  li {
    display: block;
    padding: 8px;
  }

  .nav__submenu-item {
    &:hover {
      background: rgba(#000, 0.1);
    }
  }
`;

function Submenu({ items, isOpen, handleClick }) {
  return (
    <SubmenuContainer $isOpen={isOpen}>
      {isOpen && (
        <ul className="nav__submenu">
          {items.map((item, index) => (
            <ListItem
              key={`sub${index}`}
              className="nav__submenu-item "
              onClick={() => {
                handleClick(index);
              }}
            >
              {item}
            </ListItem>
          ))}
        </ul>
      )}
    </SubmenuContainer>
  );
}

Submenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Submenu;
