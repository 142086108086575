import styled from 'styled-components';
import theme from './theme';

import { H1 } from './Typography';

export const Ribbon = styled.section`
  align-items: center;
  display: flex;
  position: relative;
  color: #ffffff;

  * {
    z-index: 1;
  }

  &::before {
    background: linear-gradient(332.22deg, ${theme.colours.active} -22.69%, #0a1b3c 96.46%);
    border-radius: 6px;
    box-shadow: 0 10px 55px rgba(222, 226, 229, 0.39);
    content: ' ';
    display: block;
    height: 100%;
    left: -30px;
    opacity: 0.89;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const RibbonText = styled(H1)`
  color: inherit;
  font-size: 25px;
  letter-spacing: -0.81px;
  line-height: 26px;
  margin: 0;
`;
