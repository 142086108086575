const theme = {
  fontFamily: 'Montserrat, sans-serif',
  maxWidth: '800px',
  navWidth: '1000px',
  progressBarWidth: '1000px',
  gutter: '0 20px',
  colours: {
    active: '#1f335b',
    background: '#fff',
    navy: '#1282A2',
    body: '#181A2F',
    grey: '#828BA0',
    heading: '#555878',
    secondary: '#ced5e1',
    warning: '#f39c00',
    white: '#fff',
  },
};

export default theme;
