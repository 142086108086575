import styled from 'styled-components';

import { Ribbon as BaseRibbon, RibbonText } from './Question';

export const InfoRibbon = styled(BaseRibbon)`
  padding-bottom: 20px;
  overflow-wrap: break-word;

  p {
    max-width: 100%;
  }
`;

export const BlueBorderedText = styled(RibbonText)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
`;
