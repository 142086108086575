import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const printableRoot = document.getElementById('printable');

class Printable extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    printableRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    printableRoot.removeChild(this.el);
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

Printable.defaultProps = {
  children: [],
};

Printable.propTypes = {
  children: PropTypes.node,
};

export default Printable;
