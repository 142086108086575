import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Container } from './styled/Wrappers';
import Nav from './Nav';
import ProgressBar from './ProgressBar';
import Matrix from './options/Matrix';
import Intro from './options/Intro';

function Options() {
  const location = useLocation();

  return (
    <>
      <Nav />
      <ProgressBar step={location.pathname === '/options' ? 3 : 4} />
      <Container>
        <Routes>
          <Route path="comparison" element={<Matrix />} />
          <Route path="*" element={<Intro />} />
        </Routes>
      </Container>
    </>
  );
}

export default Options;
