import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import happy from '../../images/happy.svg';

import { Text } from './Typography';

export const Matrix = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 310px;
`;

export const Legend = styled.div`
  h4 {
    margin-top: 0;
  }

  li {
    align-items: flex-start;
    display: flex;
    font-size: 14px;
    line-height: 1.375;

    & + li {
      margin-top: 12px;
    }

    span {
      display: flex;
      flex: 0 1 auto;
      margin-right: 13px;
    }
  }
`;

export const Summary = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 40px;
  text-align: center;
`;

const SmileyWrapper = styled.span`
  flex: 1 0 10%;
  img {
    width: 26px;
    height: 26px;
  }
`;

export function Smiley({ imgSrc }) {
  return (
    <SmileyWrapper>
      <img src={imgSrc} alt="chance" />
    </SmileyWrapper>
  );
}

Smiley.defaultProps = {
  imgSrc: happy,
};

Smiley.propTypes = {
  imgSrc: PropTypes.string,
};
