import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Container } from './styled/Wrappers';
import Nav from './Nav';
import Info from './bleeding/Info';
import Question from './bleeding/Question';
import Outro from './bleeding/Outro';
import Medication from './bleeding/Medication';
import NoMedication from './bleeding/NoMedication';
import Risk from './bleeding/Risk';
import ProgressBar from './ProgressBar';

function Bleeding() {
  const location = useLocation();

  return (
    <>
      <Nav />
      <ProgressBar step={location.pathname === '/bleeding' ? 2 : 3} />
      <Container>
        <Routes>
          <Route path="outro" element={<Outro />} />
          <Route path="risk" element={<Risk />} />
          <Route path="info" element={<Info />} />
          <Route path="no-medication" element={<NoMedication />} />
          <Route path="medication" element={<Medication />} />
          <Route path="*" element={<Question />} />
        </Routes>
      </Container>
    </>
  );
}

export default Bleeding;
