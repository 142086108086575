/*
 * ACTIONS
 */
export const ADD = 'ADD';

/*
 * ACTION CREATORS
 */
export const add = ({ answers, questionType }) => ({
  type: ADD,
  answers,
  questionType,
});

const defaultState = {};

/*
 * REDUCERS
 */
// const defaultState = {};

// eslint-disable-next-line default-param-last
const main = (state = defaultState, action) => {
  switch (action.type) {
    case ADD:
      return { ...state, [action.questionType]: action.answers };
    default:
      return state;
  }
};

export default main;
