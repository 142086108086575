import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from './styled/Typography';
import strokeChart from '../images/stroke-chart.svg';
import bleedingChart from '../images/bleeding-chart.svg';
import marker from '../images/marker.png';

const Caption = styled(Text)`
  bottom: -35px;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: capitalize;
`;

const Chart = styled.div`
  margin-bottom: 57px;
  margin-top: 42px;
  max-width: 100%;
  position: relative;

  img {
    max-width: 100%;
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 280px;

  p {
    align-items: center;
    display: flex;
    margin: 6px 0;
  }
`;

const guidelineBaseWidth = 341;
const markerWidth = 33;
const Marker = styled.img`
  height: 44px;

  left: ${(props) =>
    props.$chance * ((guidelineBaseWidth / props.$total / guidelineBaseWidth) * 100)}%;

  @media (min-width: ${guidelineBaseWidth}px) {
    transform: translateX(2px);
  }
  position: absolute;
  top: -46px;
  width: ${markerWidth}px;
`;

const charts = {
  Bleeding: bleedingChart,
  Stroke: strokeChart,
};

function RiskChart({ type, percentChance, caption, totalChance }) {
  return (
    <Chart>
      <Marker
        src={marker}
        alt="your position on the scale"
        $total={totalChance + 1}
        $chance={percentChance}
      />
      <img src={charts[type]} alt={caption} style={{ marginBottom: 10 }} />
      <Caption>{caption}</Caption>
    </Chart>
  );
}

RiskChart.propTypes = {
  caption: PropTypes.string.isRequired,
  percentChance: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['Bleeding', 'Stroke']).isRequired,
  totalChance: PropTypes.number.isRequired,
};

export default RiskChart;
