import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import answers from './answers';
import favourites from './favourites';
import risks from './risks';
import ui from './ui';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    answers,
    favourites,
    risks,
    ui,
  });

export default reducers;
