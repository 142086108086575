import { css, keyframes } from 'styled-components';

const fadeInAnim = keyframes`
  from {
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
    transition: opacity .5s ease-in-out;
  }
`;

const fadeIn = css`
  animation: 1s forwards ${fadeInAnim};
`;

export default fadeIn;
