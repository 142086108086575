import styled from 'styled-components';
import theme from './theme';
import fadeIn from './Animations';

export const Container = styled.section`
  ${fadeIn};
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  max-width: ${theme.maxWidth};
  padding: 20px;
  width: 100%;
`;

export const Grow = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Centered = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const PaddedWrapper = styled.section`
  padding: ${theme.gutter};
`;

export const Actions = styled(Centered)`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
`;
