import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import theme from '../styled/theme';
import fadeIn from '../styled/Animations';
import { Text } from '../styled/Typography';
import { Link } from '../styled/Buttons';
import { Actions, PaddedWrapper, Grow } from '../styled/Wrappers';
import { InfoRibbon } from '../styled/InfoRibbon';

const Wrapper = styled(Grow)`
  ${fadeIn}
`;

export function Recommendation({ stroke }) {
  let recommendation = '';
  if (stroke === 0) {
    recommendation = 'you do not need an oral anticoagulant';
  } else if (stroke === 1) {
    recommendation = 'you could take an oral anticoagulant';
  } else if (stroke >= 2) {
    recommendation = 'you should take an oral anticoagulant';
  }

  return (
    <InfoRibbon $background={theme.colours.secondary}>
      <Text>
        Medical guidelines recommend that <strong>{recommendation}</strong>
      </Text>
    </InfoRibbon>
  );
}

Recommendation.propTypes = {
  stroke: PropTypes.number.isRequired,
};

function Medication({ stroke }) {
  return (
    <Wrapper>
      <PaddedWrapper>
        <Text style={{ marginBottom: '30px' }}>
          Based on your score for the stroke-related questions and your chance of having a stroke:
        </Text>

        <Recommendation stroke={stroke} />

        {stroke > 0 && (
          <Text>
            By taking an oral anticoagulant, you will reduce your chance of having a stroke by
            approximately half.
          </Text>
        )}
        <Text>
          The <strong>RAPID AFib Decision Aid</strong> is not meant to act as a substitute for
          medical advice from your doctor.
        </Text>
      </PaddedWrapper>
      <Actions>
        <Link to="/bleeding">Continue</Link>
      </Actions>
    </Wrapper>
  );
}

Medication.propTypes = {
  stroke: PropTypes.number.isRequired,
};

const mapStateToProps = ({ risks: { stroke } }) => ({ stroke });

export default connect(mapStateToProps)(Medication);
