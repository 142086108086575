import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import Outro from './Outro';
import { Text } from '../styled/Typography';
import { Actions, Centered } from '../styled/Wrappers';
import { TextLink } from '../styled/Buttons';
import * as Q from '../styled/Question';
import { AGE_QUESTION, PRIOR_STROKE_QUESTION, STROKE_QUESTIONS } from '../../config/questions';
import { setAge, setStroke } from '../../reducers/ui';

const FullHeightTransition = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.$grow ? '1' : '0 1 auto')};
  position: relative;
`;

function Question({ dispatch }) {
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [back, setBack] = useState(false);
  const { question, options } = STROKE_QUESTIONS[index] || { options: [] };
  const questionCount = STROKE_QUESTIONS.length;
  const isInitialMount = useRef(true);
  const handleBack = () => {
    setBack(true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (back) {
      setIndex((idx) => idx - 1);
    }
  }, [back]);

  const score = STROKE_QUESTIONS.reduce(
    (sum, cur, i) => sum + (cur.options[(answers[i] || {}).answer] || 0),
    0
  );

  const completed = index >= questionCount;

  return (
    <FullHeightTransition $grow={completed}>
      <CSSTransition
        key={question}
        classNames="page"
        timeout={{ enter: 1100, exit: 900 }}
        onExited={() => {
          setBack(false);
        }}
      >
        {(state) => {
          if (completed) {
            return (
              <Outro
                score={score}
                onBack={handleBack}
                answers={answers}
                previous={back}
                state={state}
              />
            );
          }

          return (
            <Q.Wrapper className="question" $previous={back}>
              <Q.Header>
                {index === 0 && (
                  <Q.IntroText>
                    Please answer the following stroke-related questions. Your answers to these
                    questions will help you determine your chance of stroke.
                  </Q.IntroText>
                )}
                <Q.Ribbon>
                  <Q.RibbonText>
                    {`Stroke Question ${index + 1}`}
                    <Q.MutedText>{` of ${questionCount}`}</Q.MutedText>
                  </Q.RibbonText>
                  <Text dangerouslySetInnerHTML={{ __html: question }} />
                </Q.Ribbon>
              </Q.Header>
              <Centered>
                {Object.keys(options).map((o, i) => (
                  <Q.CustomButton
                    key={`q${index}o${i}`}
                    selected={(answers[index] || {}).answer === Object.keys(options)[i]}
                    onClick={() => {
                      const selected = { question, answer: Object.keys(options)[i] };
                      const newAnswers = [
                        ...answers.slice(0, index),
                        selected,
                        ...answers.slice(index + 1),
                      ];

                      // Record age & stroke info for later use in bleeding scoring
                      if (question === AGE_QUESTION) {
                        dispatch(setAge(selected));
                      } else if (question === PRIOR_STROKE_QUESTION && selected.answer === 'Yes') {
                        dispatch(setStroke(true));
                      }

                      document.querySelector('#root > section').scrollTop = 0;

                      setAnswers(newAnswers);
                      setIndex(index + 1);
                    }}
                  >
                    {o}
                  </Q.CustomButton>
                ))}
                <Actions>{index > 0 && <TextLink onClick={handleBack}>Previous</TextLink>}</Actions>
              </Centered>
            </Q.Wrapper>
          );
        }}
      </CSSTransition>
    </FullHeightTransition>
  );
}

Question.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Question);
