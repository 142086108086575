import React from 'react';
import styled from 'styled-components';

import { ListItem, Text } from '../styled/Typography';
import { Link } from '../styled/Buttons';
import { Grow } from '../styled/Wrappers';
import fadeIn from '../styled/Animations';

const Action = styled(Link)`
  align-self: center;
  margin-top: auto;
`;

const Wrapper = styled(Grow)`
  ${fadeIn}
`;

function Medication() {
  return (
    <Wrapper>
      <Text>If you decide to take an oral anticoagulant, you have a number of options.</Text>
      <Text>The oral anticoagulants used for atrial fibrillation are:</Text>
      <ul>
        <ListItem>warfarin (Coumadin®)</ListItem>
        <ListItem>apixaban (Eliquis®)</ListItem>
        <ListItem>dabigatran (Pradaxa®)</ListItem>
        <ListItem>edoxaban (Lixiana®)</ListItem>
        <ListItem>rivaroxaban (Xarelto®)</ListItem>
      </ul>
      <Text>
        While these oral anticoagulants all reduce your chance of stroke, they also increase your
        chance of bleeding.
      </Text>
      <Text>
        There are also subtle differences between the oral anticoagulants and their dose frequency,
        interactions with food and drugs, blood testing requirements, potential common side effects
        and costs.
      </Text>
      <Action to="/options">Continue</Action>
    </Wrapper>
  );
}

export default Medication;
