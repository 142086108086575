import { connect } from 'react-redux';

import Risk from '../Risk';

export const scoreInWords = (score) => {
  if (score >= 2) {
    return 'high';
  }
  if (score === 1) {
    return 'moderate';
  }

  return 'low';
};

function mapStateToProps({ risks: { stroke } }) {
  const totalChance = 8;
  const percentChance = stroke;
  const desc = scoreInWords(stroke);

  return {
    chanceDescriptor: 'having a stroke',
    onContinueRoute: '/stroke/no-medication',
    percentChance,
    score: stroke,
    scoreInWords: desc,
    totalChance,
    type: 'Stroke',
  };
}

export default connect(mapStateToProps)(Risk);
