import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import arrow from '../../images/back.svg';

const AccordionWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #f7f7f7;
  border-radius: 6px;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
  }

  label {
    display: block;
  }

  img {
    transition: transform 0.2s ease-in-out;
    position: absolute;
    right: 15px;
    top: 21px;
  }

  section.content {
    max-height: 0;
    transition: 0.3s max-height;
    overflow: hidden;
  }

  input:checked ~ section.content {
    max-height: 150px;
    height: auto;

  input:checked ~ img {
    transform: rotate(180deg);
  }
`;

function Accordion({ children, disabled, inputId }) {
  const [checked, setChecked] = useState(false);
  return (
    <AccordionWrapper>
      {!disabled && (
        <input
          id={inputId}
          type="checkbox"
          name="toggle"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      )}
      {children}
      <img src={arrow} alt={checked ? 'not-collapsed' : 'collapsed'} />
    </AccordionWrapper>
  );
}

Accordion.defaultProps = {
  disabled: true,
};

Accordion.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  disabled: PropTypes.bool,
  inputId: PropTypes.string.isRequired,
};

export default Accordion;
