import React from 'react';

import Nav from './Nav';
import { Text } from './styled/Typography';
import { Container, Grow, Centered } from './styled/Wrappers';
import { Link } from './styled/Buttons';

function ThankYou() {
  return (
    <>
      <Nav />
      <Container>
        <Grow>
          <Text>
            Thank you for considering the RAPID AFib Decision Aid, but this Decision Aid is not
            right for you.
          </Text>
        </Grow>
        <Centered>
          <Link to="/">Back</Link>
        </Centered>
      </Container>
    </>
  );
}

export default ThankYou;
