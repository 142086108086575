import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import theme from '../styled/theme';
import Favourite from './Favourite';
import Plus from '../../images/add.svg';
import Times from '../../images/times.svg';
import { Button, CircleButton, TextLink } from '../styled/Buttons';
import { Actions, Grow } from '../styled/Wrappers';
import * as Question from '../styled/Question';
import { Text } from '../styled/Typography';
import DRUGS from '../../config/drugs';
import Submenu from './Submenu';
import Accordion from './Accordion';

function Matrix({ bleeding, stroke }) {
  const navigate = useNavigate();
  const [primaryOpen, setPrimaryOpen] = useState(false);
  const [secondaryOpen, setSecondaryOpen] = useState(false);
  const [primaryIndex, setPrimaryIndex] = useState(null);
  const [secondaryIndex, setSecondaryIndex] = useState(null);
  const primary = DRUGS[primaryIndex];
  const primaryDrugs = DRUGS.map((drug) => drug.name);
  const secondary = DRUGS[secondaryIndex];
  const secondaryDrugs = DRUGS.map((drug) => drug.name);

  if (primaryIndex !== null) {
    delete secondaryDrugs[primaryIndex];
    if (secondaryIndex === null) {
      setSecondaryIndex(secondaryDrugs.findIndex((d) => d !== undefined));
    }
  }

  if (secondaryIndex !== null) {
    delete primaryDrugs[secondaryIndex];
    if (primaryIndex === null) {
      setPrimaryIndex(primaryDrugs.findIndex((d) => d !== undefined));
    }
  }
  const accordionOpen = Boolean(primary && secondary);
  return (
    <>
      <Wrapper>
        <Drug $hasDrug={primary}>
          <div
            onKeyDown={() => setPrimaryOpen(!primaryOpen)}
            className="header"
            onClick={() => setPrimaryOpen(!primaryOpen)}
            role="button"
            tabIndex={0}
          >
            <Add>
              <img src={primary ? Times : Plus} alt="plus" />
            </Add>
            <Text>{primary ? 'Change' : 'Add Oral Anticoagulant'}</Text>
            <Submenu
              items={primaryDrugs}
              isOpen={primaryOpen}
              handleClick={setPrimaryIndex}
              onClick={() => {
                setPrimaryOpen(false);
              }}
            />
          </div>
          {primary && (
            <div>
              <Favourite drug={primary.name}>&#9733;</Favourite>
              <Text>{primary.name}</Text>
            </div>
          )}
        </Drug>
        <Drug className="secondary" $hasDrug={secondary}>
          <div
            onKeyDown={() => setPrimaryOpen(!primaryOpen)}
            className="header"
            onClick={() => setSecondaryOpen(!secondaryOpen)}
            role="button"
            tabIndex={0}
          >
            <Add>
              <img src={secondary ? Times : Plus} alt="plus" />
            </Add>
            <Text>{secondary ? 'Change' : 'Add Oral Anticoagulant'}</Text>
            <Submenu
              items={secondaryDrugs}
              isOpen={secondaryOpen}
              handleClick={setSecondaryIndex}
              onClick={() => {
                setSecondaryOpen(false);
              }}
            />
          </div>
          {secondary && (
            <div>
              <Favourite drug={secondary.name}>&#9733;</Favourite>
              <Text>{secondary.name}</Text>
            </div>
          )}
        </Drug>
      </Wrapper>
      <Scroll>
        <div>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="stroke">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="stroke">Chance of Stroke</label>
              </Question.RibbonText>
              <section className="content">
                <Text>
                  The number of people in a group of 100 people like you who will probably have a
                  stroke in the next year.
                </Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.chanceOfStroke[stroke]}</Text>
                    <Text>{secondary.chanceOfStroke[stroke]}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="bleeding">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="bleeding">Chance of Bleeding</label>
              </Question.RibbonText>
              <section className="content">
                <Text>
                  The number of people in a group of 100 people like you who will probably have a
                  serious bleeding problem in the next year
                </Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.chanceOfBleeding[bleeding]}</Text>
                    <Text>{secondary.chanceOfBleeding[bleeding]}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="dose">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="dose">Dose Frequency</label>
              </Question.RibbonText>
              <section className="content">
                <Text>The number of times the drug needs to be taken each day.</Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.doseFrequency}</Text>
                    <Text>{secondary.doseFrequency}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="foodAndDrug">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="foodAndDrug">Food &amp; Drug Interactions</label>
              </Question.RibbonText>
              <section className="content">
                <Text>
                  A change in a drug’s effect on the body when the drug is taken together with
                  certain foods or drugs.
                </Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.foodAndDrugInteractions}</Text>
                    <Text>{secondary.foodAndDrugInteractions}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="bloodTest">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="bloodTest">Blood Testing Requirements</label>
              </Question.RibbonText>
              <section className="content">
                <Text>The need to go to a lab for bloodwork related to this medication.</Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.bloodTestingRequirement}</Text>
                    <Text>{secondary.bloodTestingRequirement}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="sideEffects">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="sideEffects">Common Side Effects</label>
              </Question.RibbonText>
              <section className="content">
                <Text>A secondary, typically undesirable effect of the drug.</Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.commonSideEffects}</Text>
                    <Text>{secondary.commonSideEffects}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="cost">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="cost">Cost</label>
              </Question.RibbonText>
              <section className="content">
                <Text>
                  The approximate monthly cost of the drug without any insurance coverage, in
                  Canada.
                </Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.cost}</Text>
                    <Text>{secondary.cost}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
          <Ribbon>
            <Accordion disabled={!accordionOpen} inputId="reversibility">
              <Question.RibbonText>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="reversibility">Reversibility</label>
              </Question.RibbonText>
              <section className="content">
                <Text>The drug’s effects can be reversed quickly in an emergency.</Text>
                {accordionOpen && (
                  <Comparison>
                    <Text>{primary.reversibility}</Text>
                    <Text>{secondary.reversibility}</Text>
                  </Comparison>
                )}
              </section>
            </Accordion>
          </Ribbon>
        </div>
      </Scroll>
      <Actions style={{ marginTop: 20 }}>
        <TextLink onClick={() => navigate(-1)}>Previous</TextLink>
        <Button onClick={() => navigate('/summary')}>Continue</Button>
      </Actions>
    </>
  );
}

Matrix.propTypes = {
  bleeding: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
};

const Add = styled(CircleButton)`
  background: #fff;
  font-size: 18px;
`;

const Comparison = styled.div`
  display: flex;
  padding: 0;

  p {
    background: #eceff4;
    border-radius: 0 0 0 6px;
    color: ${theme.colours.bodyDarker};
    flex: 1;
    font-size: 13px;
    margin: 0;
    padding: 16px 21px;
  }

  p + p {
    border-radius: 0 0 6px 0;
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2);
    position: relative;
  }
`;

const Drug = styled(Grow)`
  flex: 1;

  &.secondary {
    .header {
      background: linear-gradient(90deg, ${theme.colours.active} 68%, #828ba0 116.68%);
      border-radius: ${({ $hasDrug }) => ($hasDrug ? '0 4px 0 0' : '0 4px 4px 0')};
    }

    .header + div {
      box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2);
    }
  }

  .header {
    align-items: center;
    background: linear-gradient(90deg, ${theme.colours.active} 68%, #828ba0 116.68%);
    border-radius: ${({ $hasDrug }) => ($hasDrug ? '4px 0 0 0' : '4px 0 0 4px')};
    display: flex;
    padding: 9px 15px;
    position: relative;

    p {
      flex: 1;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      margin: 0;
    }
  }

  .header + div {
    align-items: center;
    background: ${theme.colours.secondary};
    display: flex;
    padding: 11px 15px;
    position: relative;
    flex: 1;

    p {
      font-weight: bold;
      margin: 0;
    }
  }
`;

const Ribbon = styled(Question.Ribbon)`
  box-shadow: none;
  padding: 0;
  overflow: hidden;
  margin-top: 15px;

  h1 {
    margin: 18px 0;
    padding: 0 21px 0 21px;
    &:before {
      left: 0;
    }
  }

  .content > p {
    padding: 0 18px;
  }

  @media (min-width: 370px) {
    margin-top: 15px;
  }
`;

const Scroll = styled(Grow)`
  overflow: auto;
  position: relative;
  scroll-behavior: smooth;

  > div {
    position: absolute;
  }
`;

const Wrapper = styled.div`
  display: flex;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 2px 9px rgba(181, 181, 181, 0.573727);
`;

function mapStateToProps({ risks: { bleeding, stroke } }) {
  return {
    bleeding,
    stroke,
  };
}

export default connect(mapStateToProps)(Matrix);
