import { connect } from 'react-redux';

import Risk from '../Risk';

export const scoreInWords = (score) => {
  if (score >= 3) {
    return 'high';
  }
  if (score === 2) {
    return 'moderate';
  }

  return 'low';
};

function mapStateToProps({ risks: { bleeding } }, { history }) {
  const totalChance = 9;
  const percentChance = bleeding;
  const desc = scoreInWords(bleeding);

  return {
    chanceDescriptor: 'having a bleeding problem',
    onContinueRoute: '/bleeding/info',
    percentChance,
    score: bleeding,
    scoreInWords: desc,
    totalChance,
    type: 'Bleeding',
  };
}

export default connect(mapStateToProps)(Risk);
