import styled, { keyframes } from 'styled-components';

import { Grow } from './Wrappers';
import { Button } from './Buttons';
import theme from './theme';
import { Text } from './Typography';
import { Ribbon as BaseRibbon, RibbonText as BaseRibbonText } from './Ribbon';

const slideInLeft = keyframes`
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .5s ease-in-out;
  }
`;

const slideInRight = keyframes`
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .5s ease-in-out;
  }
`;

const slideOutLeft = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
    transition: opacity .5s ease-out;
    visibility: hidden;
  }
`;

const slideOutRight = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    transition: opacity .5s ease-out;
    visibility: hidden;
  }
`;

export const CustomButton = styled(Button)`
  ${(props) =>
    props.selected ? `background-color: ${theme.colours.navy}` : 'background-color: #fff'};
  border-radius: 6px;
  border: ${(props) => (props.selected ? 0 : 1)}px solid ${theme.colours.navy};
  color: ${(props) => (props.selected ? '#fff' : theme.colours.navy)};
  margin-bottom: 16px;
  width: 295px;

  &:active {
    background-color: ${theme.colours.navy};
    color: #fff;
    border: 0;
  }
`;

const horizontalPadding = 19;
export const Ribbon = styled(BaseRibbon)`
  align-items: start;
  box-shadow: 0 10px 55px rgba(222, 226, 229, 0.39);
  color: ${theme.colours.body};
  flex-direction: column;
  height: auto;
  margin-bottom: 30px;
  padding: 16px 0 16px ${horizontalPadding}px;

  &::before {
    background-color: ${(props) => (props.$background ? props.$background : '#fff')};
    background-image: none;
    left: 0;
    right: -30px;
    top: 0;
  }

  @media print {
    padding: 16px ${horizontalPadding}px;

    border: 1px solid #fefefe;
    box-shadow: 0 10px 55px rgba(222, 226, 229, 0.9);

    &::before {
      display: none;
    }
  }

  p {
    max-width: 100%;
  }

  @media (min-width: 370px) {
    margin-top: 10px;
  }
`;

const size = 10;
export const RibbonText = styled(BaseRibbonText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  &::before {
    background-color: ${(props) => (props.borderColor ? props.borderColor : theme.colours.active)};
    border-bottom-right-radius: ${size * 2}px;
    border-top-right-radius: ${size * 2}px;
    content: '';
    height: ${size * 2}px;
    left: -${horizontalPadding}px;
    position: absolute;
    top: 0;
    width: ${size}px;
  }
`;

export const Header = styled.header`
  padding: ${theme.gutter};
`;

export const IntroText = styled(Text)`
  margin-bottom: 32px;
`;

export const MutedText = styled.span`
  color: ${theme.colours.active};
`;

export const Wrapper = styled(Grow)`
  &.page-enter {
    animation: 1s forwards ${(props) => (props.$previous ? slideInLeft : slideInRight)};
  }

  &.page-exit {
    animation: 0.5s forwards ${(props) => (props.$previous ? slideOutRight : slideOutLeft)};
    overflow: hidden;
    position: absolute;
    top: ${(props) => (props.grow ? '0' : 'auto')};
    height: ${(props) => (props.grow ? '100%' : 'auto')};
  }

  background-color: #fafcfe;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
