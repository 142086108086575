export const AGE_QUESTION = 'To which <strong>age group</strong> do you belong?';
export const PRIOR_STROKE_QUESTION =
  'Have you ever been diagnosed with a <strong>stroke or transient ischemic attack (TIA or mini-stroke)</strong>?';

export const STROKE_QUESTIONS = [
  {
    question: AGE_QUESTION,
    options: {
      '64 Years or Younger': 0,
      '65 to 74 Years': 1,
      '75 Years or Older': 2,
    },
  },
  {
    question: 'Have you ever been diagnosed with <strong>heart failure</strong>?',
    options: {
      Yes: 1,
      No: 0,
      'I’m Not Sure': 0,
    },
  },
  {
    question: 'Have you ever been diagnosed with <strong>high blood pressure</strong>?',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question: 'Have you ever been diagnosed with <strong>Type 1 or Type 2 Diabetes</strong>?',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question:
      'Have you ever had a <strong>heart attack, heart stent or heart bypass surgery</strong>?',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question: PRIOR_STROKE_QUESTION,
    options: {
      Yes: 2,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
];

export const BLEEDING_QUESTIONS = [
  {
    question: 'Do you have <strong>high blood pressure</strong>?',
    options: {
      Yes: {
        question:
          'Have you had any recent readings where your <strong>systolic pressure</strong> (top number) was greater than 160 mmHg?',
        options: {
          Yes: 1,
          No: 0,
          "I'm Not Sure": 0,
        },
      },
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question:
      'Have you ever been diagnosed with <strong>kidney disease that required the care of a specialist</strong>?',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question:
      'Have you ever been diagnosed with <strong>liver disease that required the care of a specialist</strong>?',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question:
      'Have you ever had <strong>bleeding or low blood levels that required the care of a specialist</strong>?',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question:
      'Are you <strong>currently taking blood thinners</strong>? [For example: warfarin (Coumadin®), acetylsalicylic acid (Aspirin®), clopidogrel (Plavix®), ticagrelor (Brilinta®), prasugrel (Effient®), etc.].',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question: 'Are you <strong>currently taking warfarin (Coumadin®)</strong>?',
    options: {
      Yes: {
        question:
          'Do you need to <strong>adjust your dose frequently</strong>? (For example: more than twice a month).',
        options: {
          Yes: 1,
          No: 0,
          "I'm Not Sure": 0,
        },
      },
      No: 0,
      "I'm Not Sure": 0,
    },
  },
  {
    question:
      'Do you regularly consume <strong>8 or more standard alcoholic drinks</strong> each week? [One standard drink of alcohol equals: one bottle of beer (341 ml), a glass of wine (5 oz) or a shot of 40% hard liquor (1.5 oz)].',
    options: {
      Yes: 1,
      No: 0,
      "I'm Not Sure": 0,
    },
  },
];
