import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import analytics from './analytics';

const defaultState = {};

export const history = createBrowserHistory();

const middlewares = [thunk, analytics, routerMiddleware(history)];
let enhancers;
if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  // eslint-disable-line
  enhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(...middlewares)); // eslint-disable-line
} else {
  enhancers = applyMiddleware(...middlewares);
}

const store = createStore(createRootReducer(history), defaultState, enhancers);

export default store;
