import React, { useState } from 'react';
import styled from 'styled-components';
import accordionScreenshot from '../../images/accordion_screenshot.png';
import changeScreenshot from '../../images/change_screenshot.png';
import favouriteScreenshot from '../../images/favourite_screenshot.png';
import { H2 } from '../styled/Typography';
import fadeIn from '../styled/Animations';
import { Link } from '../styled/Buttons';
import { Grow, PaddedWrapper } from '../styled/Wrappers';
import { InfoRibbon, BlueBorderedText } from '../styled/InfoRibbon';
import Screenshot from '../styled/Screenshot';

const Action = styled(Link)`
  align-self: center;
  margin-top: auto;
`;

const Wrapper = styled(Grow)`
  ${fadeIn}
`;

function PageOne() {
  return (
    <Wrapper>
      <H2>Treatment Options Comparison</H2>
      <InfoRibbon>
        <BlueBorderedText>
          The following pages are designed to help you think about what is important to you so that
          you can decide which, if any, oral anticoagulants are best for you.
        </BlueBorderedText>
      </InfoRibbon>
    </Wrapper>
  );
}

function PageTwo() {
  return (
    <Wrapper>
      <H2>Treatment Options Comparison (cont’d.)</H2>
      <InfoRibbon>
        <BlueBorderedText>
          You will be able to learn about and compare the specific characteristics of these two
          treatment options side-by-side, by expanding any of the rows that are of interest to you.
        </BlueBorderedText>
        <Screenshot src={accordionScreenshot} alt="Screenshot of comparison interface" />
      </InfoRibbon>
    </Wrapper>
  );
}

function PageThree() {
  return (
    <Wrapper>
      <H2>Treatment Options Comparison (cont’d.)</H2>
      <InfoRibbon>
        <BlueBorderedText>
          If you would like to learn about another treatment option, click on one of the “Change”
          buttons located at the top of the columns and select from the list the treatment option
          you would like to look at next.
          <Screenshot src={changeScreenshot} alt="Screenshot of change medication buttons" />
        </BlueBorderedText>
      </InfoRibbon>

      <InfoRibbon>
        <BlueBorderedText>
          Repeat this until you have looked at as many treatment options as you want.
        </BlueBorderedText>
      </InfoRibbon>
    </Wrapper>
  );
}

function PageFour() {
  return (
    <Wrapper>
      <H2>Treatment Options Comparison (cont’d.)</H2>
      <InfoRibbon>
        <BlueBorderedText>
          If you prefer any of the treatment options, click on the star icon (&#9733;) that appears
          beside their names.
        </BlueBorderedText>
        <Screenshot src={favouriteScreenshot} alt="Screenshot of the location of the star icon" />
      </InfoRibbon>

      <InfoRibbon>
        <BlueBorderedText>
          This will add the treatment option(s) to a preferred treatments list that will appear on
          the summary page at the end, so that you can discuss them further with your doctor.
          Clicking on the star icon a second time will remove that treatment options from your
          preferred treatments list. Click the “Compare” button to begin.
        </BlueBorderedText>
      </InfoRibbon>
    </Wrapper>
  );
}

const Pages = [PageOne, PageTwo, PageThree, PageFour];

function Intro() {
  const [index, setIndex] = useState(0);

  const PageComponent = Pages[index];

  return (
    <Grow>
      <PaddedWrapper>
        <PageComponent />
      </PaddedWrapper>
      <Action
        to={index === 3 ? '/options/comparison' : null}
        onClick={() => {
          if (index < 3) {
            setIndex(index + 1);
          }
        }}
      >
        {index === 3 ? 'Compare' : 'Continue'}
      </Action>
    </Grow>
  );
}

export default Intro;
